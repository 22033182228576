import * as ActionTypes from "./types"
import { toast } from "react-toastify"

export interface EnvironmentState {
	overriddenDate: string
	generateAssessmentsInProgress: boolean
	sendOverdueEmailsInProgress: boolean
	sendInviteReminderEmailsInProgress: boolean
}

const initialTenancy: EnvironmentState = {
	overriddenDate: "",
	generateAssessmentsInProgress: false,
	sendOverdueEmailsInProgress: false,
	sendInviteReminderEmailsInProgress: false
}

function tenancyReducer(
	state: EnvironmentState = initialTenancy,
	action: any
): EnvironmentState {
	switch (action.type) {
		case ActionTypes.SET_OVERRIDE_DATE:
			return {
				...state,
				overriddenDate: action.newDate
			}
		case ActionTypes.TENANT_AUTOMATION_SEND_OVERDUE_EMAILS_REQUEST:
			return {
				...state,
				sendOverdueEmailsInProgress: true
			}
		case ActionTypes.TENANT_AUTOMATION_SEND_OVERDUE_EMAILS_RECEIVE:
			toast.success(
				"Started overdue emails send. Check Slack for progress."
			)
			return {
				...state,
				sendOverdueEmailsInProgress: false
			}
		case ActionTypes.TENANT_AUTOMATION_SEND_OVERDUE_EMAILS_FAILURE:
			toast.error("Something went wrong sending overdue emails")
			return {
				...state,
				sendOverdueEmailsInProgress: false
			}
		case ActionTypes.TENANT_AUTOMATION_GENERATE_ASSESSMENTS_REQUEST:
			return {
				...state,
				generateAssessmentsInProgress: true
			}
		case ActionTypes.TENANT_AUTOMATION_GENERATE_ASSESSMENTS_RECEIVE:
			toast.warn(
				"Started assessment generation. Check Slack for progress."
			)
			return {
				...state,
				generateAssessmentsInProgress: false
			}
		case ActionTypes.TENANT_AUTOMATION_GENERATE_ASSESSMENTS_FAILURE:
			toast.error("Something went wrong generating assessments")
			return {
				...state,
				generateAssessmentsInProgress: false
			}
		case ActionTypes.TENANT_AUTOMATION_SEND_INVITE_REMINDER_EMAILS_REQUEST:
			return {
				...state,
				sendInviteReminderEmailsInProgress: true
			}
		case ActionTypes.TENANT_AUTOMATION_SEND_INVITE_REMINDER_EMAILS_RECEIVE:
			toast.success(
				"Started invite reminder send. Check Slack for progress."
			)
			return {
				...state,
				sendInviteReminderEmailsInProgress: false
			}
		case ActionTypes.TENANT_AUTOMATION_SEND_INVITE_REMINDER_EMAILS_FAILURE:
			toast.error("Something went wrong sending invite reminder emails")
			return {
				...state,
				sendInviteReminderEmailsInProgress: false
			}
	}
	return state
}

export default tenancyReducer
